<template>
  <div class="box" style="background: linear-gradient(180deg, #00B5A0 0%, #0C9793 100%);">
    <div class="top-box">
          <div>
              <p>Hi，业主顾客您好</p>
              <div class="riqi">
                  <img style="width:18px;height:18px;" src="https://middle-ground.shulanhealth.com/rili-icon%402x.png" alt="">
                  <span>今天是 {{toDay}}，天气{{weatherInfo.weather}}，气温{{weatherInfo.temperature}}°</span>
              </div>
          </div>
          <!-- <img class="head-sty" :src="'https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTL4Mluv3Vq2pwFZVfbeYUelic7xMjAheDCsQcvGVhG3lg6TpRrEZA7fQJzw2FduyWIGnBYFfbJq7nA/132'" alt=""> -->
      </div>
      <!-- <div class="dcx" v-if="myTx != null" @click="showPassengerInfo">你有1个行程</div> -->
    <div class="bar-box" style="margin-top:20px;">
      <!-- <div class="bar-item" @click="goTo" style="border-top-left-radius: 15px;">
        <img
          :src="'https://middle-ground.shulanhealth.com/jkgl/img-08.png'"
          alt="img"
        />
        <span>新增菜品</span>
      </div> -->
      <div class="bar-item" @click="goMy" style="border-top-right-radius: 15px;border-top-left-radius: 15px;">
        <img
          :src="'https://middle-ground.shulanhealth.com/jkgl/img-10.png'"
          alt="img"
        />
        <span>我的预约</span>
      </div>
    </div>
    <van-tree-select
      height="calc(100vh - 125px)"
      :items="items"
      :main-active-index.sync="active"
      @click-nav="searchXc"
    >
      <template #content>
        <div style="height:calc(100vh - 125px);background:#fff;">
          <div class="item-box" v-for="item in caiList" :key="item.id">
            <img style="width:30%;border-radius: 5px;" :src="formatPicUrl(item.cPic)" alt="">
            <div style="margin-left:10px;width:70%;height: 70px;display: flex;flex-direction: column;justify-content: space-between;">
              <div class="cptitle">{{item.cName}}</div>
              <div class="cptitle"><van-tag type="warning">{{item.cPrice}}元/{{item.cDw}}</van-tag></div>
              <div style="display:flex;justify-content: flex-end;">
                <div>
                  <van-stepper :min="0" :default-value="0" v-model="item.cNumber" :show-input="item.cNumber==''? false : true" :show-minus="item.cNumber==''? false : true" theme="round" button-size="20" disable-input @plus="plusCai(item)" @minus="minusCai(item)"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </van-tree-select>
    <div class="btm-box">
      <div></div>
      <div class="gwc-box" @click="showPupon">
        <img style="width:30px;" src="./comm/gwc-icon.png" alt="">
        <div class="redpoint" v-if="myGoodsList.length!=0">{{getGoodsNum()}}</div>
      </div>
      <div>共{{getGoodsYuan()}}元</div>
      <div class="sub" v-if="myGoodsList.length!=0" @click="yy">提交预约</div>
      <div class="sub1" v-else>提交预约</div>
    </div>
    <van-popup v-model="showZjlxPopup" position="bottom">
          <div style="width: 100%;max-height:200px;margin-bottom: 30px;">
            <div class="item-box" style="height:55px;" v-for="item in myGoodsList" :key="item.id">
            <!-- <img style="width:30%;border-radius: 5px;" :src="formatPicUrl(item.cPic)" alt=""> -->
            <div style="margin-left:10px;width:100%;height: 30px;display: flex;justify-content: space-between;align-items: center;">
              <div>
                <div class="cptitle">{{item.cName}}</div>
                <div class="cptitle"><van-tag type="warning">{{item.cPrice}}元/{{item.cDw}}</van-tag></div>
              </div>
              
              <div style="display:flex;justify-content: flex-end;">
                <div style="margin-right:10px;">共{{getGoodsSingleYuan(item)}}元</div>
                <div>
                  <van-stepper :min="0" :default-value="0" v-model="item.cNumber" :show-input="item.cNumber==''? false : true" :show-minus="item.cNumber==''? false : true" theme="round" button-size="20" disable-input @plus="plusCai(item)" @minus="minusCai(item)"/>
                </div>
              </div>
            </div>
          </div>
          </div>
    </van-popup>
    <loading :show="isShowLoading"></loading>
  </div>
</template>
<script>
import { Tag,Dialog,Toast,TreeSelect,Stepper,Popup } from "vant";
import dayjs from 'dayjs'
import AMap from "AMap"; // 引入高德地图
// import Empty from "./comm/empty.vue";
import Loading from "./comm/loading.vue";
import { passenger } from "@/api";
import Config from "@/config";
import Watermark from '@/utils/watermark.js'
export default {
  components: {
    // Empty: Empty,
    Loading: Loading,
    VanTag: Tag,
    VanTreeSelect: TreeSelect,
    VanStepper: Stepper,
    VanPopup: Popup,
  },
  data() {
    return {
      showZjlxPopup: false,
      caiList:[],
      activeId: "",
      active: 0,
      opacitys: 0,
      opacitys1: 0,
      items: [
        { text: "蔬菜"},
        { text: "肉禽蛋"},
        { text: "水产海鲜"},
        { text: "水果"},
        { text: "速冻食品"},
        { text: "粮油调味"},
        { text: "酒水饮料"},
        { text: "卤味熟食"},
        { text: "日用百货"},
      ],
      isShowLoading: true,
      value: 0,
      switch1: false,
      switch2: false,
      option: [
        { text: "全部区域", value: 0 },
        { text: "西湖区", value: 1 },
        { text: "滨江区", value: 2 },
      ],
      sfcList: [],
      myInfoType: null,
      myInfo: null,
      toDay: "",
      weatherInfo: null,
      myTx: null,
      myGoodsList: []
    };
  },

  mounted() {
    document.title = "预约";
    Watermark.set("Say Hi 买菜")
    this.toDay = dayjs().format('YYYY年MM月DD日')
    // let token = JSON.parse(this.getQueryString("result")).result.token;
    // localStorage.setItem("token", token);
    // this.isTaikang = localStorage.getItem("companyid") == 18 ? true : false
    // this.getAssessmentListAscy()
    // this.getMyToken()
    // this.getMyPassenger()
    this.myInfoType = JSON.parse(localStorage.getItem("user")).types
        this.myInfo = JSON.parse(localStorage.getItem("user"))
        this.getMyPassenger()
    //加载天气查询插件
    let _this = this
    AMap.plugin('AMap.Weather', function() {
        //创建天气查询实例
        var weather = new AMap.Weather();

        //执行实时天气信息查询
        weather.getLive('杭州市', function(err, data) {
            console.log(err, data);
            _this.weatherInfo = data
        });
    });
    // console.log(this.dayjs().startOf('date'))
  },
  methods: {
    yy() {
      Dialog.confirm({
        title: 'Hi',
        message:
          '确定提交预约吗？',
      })
        .then(() => {
          let params = {
            cArray: this.myGoodsList
          }
          passenger.cpyy(params).then(res=>{
            if(res.data.code == 200) {
              Toast.success('预约成功');
              this.myGoodsList = []
              this.getMyPassenger()
            }
          })
        })
        .catch(() => {
          // on cancel
        });
      
    },
    getGoodsNum() {
      let n = 0
      for(let i in this.myGoodsList) {
        n += this.myGoodsList[i].cNumber
      }
      return n
    },
    getGoodsYuan() {
      let n = 0
      for(let i in this.myGoodsList) {
        n += parseFloat(this.myGoodsList[i].cPrice * this.myGoodsList[i].cNumber)
      }
      return n
    },
    getGoodsSingleYuan(e) {
      let n = 0
      n += parseFloat(e.cPrice * e.cNumber)
      return n
    },
    showPupon() {
      if(this.myGoodsList.length == 0) {
        Toast.fail('暂无加购的商品');
        return
      }
      this.showZjlxPopup = true
    },
    plusCai(e) {
      let index = this.myGoodsList.map((item) => item.id).indexOf(e.id);
      if(index == -1) {
        this.myGoodsList.push(e)
      } else {
        this.myGoodsList[index].cNumber += 1
      }
      this.getMyPassenger()
      console.log(index)
      console.log(this.myGoodsList)
    },
    minusCai(e) {
      console.log(e)
      let index = this.myGoodsList.map((item) => item.id).indexOf(e.id);
      this.myGoodsList[index].cNumber -= 1
      if(this.myGoodsList[index].cNumber == 0) {
        this.myGoodsList.splice(index, 1);
      }
      this.getMyPassenger()
      console.log(index)
      console.log(this.myGoodsList)
    },
    getMyPassenger() {
      let params = {
        searchkey: this.items[this.active].text,
      }
      passenger.getGkCaiFb(params).then(res => {
        if(res.data.code == 200) {
          this.caiList = []
          for(let i in res.data.data.data) {
            let n = 0
            for(let j in this.myGoodsList) {
              if(res.data.data.data[i].id == this.myGoodsList[j].id) {
                n = this.myGoodsList[j].cNumber
              }
            }
            this.caiList.push({
              cDw: res.data.data.data[i].cDw,
              cName: res.data.data.data[i].cName,
              cPic: res.data.data.data[i].cPic,
              cPrice: res.data.data.data[i].cPrice,
              category: res.data.data.data[i].category,
              create_at: res.data.data.data[i].create_at,
              id: res.data.data.data[i].id,
              status: res.data.data.data[i].status,
              userId: res.data.data.data[i].userId,
              cNumber: n
            })
          }
          // this.caiList = res.data.data.data
        }
        this.isShowLoading = false
      })
    },
    formatPicUrl(e) {
      let ls = e.split(',')

      return Config.resource.base + ls[0]
    },
    formatCarNo(e) {
      const nBegin = e.substring(0,2)
      const nEnd = e.substring(e.length - 2, e.length)
      return nBegin + '***' + nEnd
    },
    goTo() {
      this.$router.push({
            name: 'add-cp',
            query: {
              isAdd: 'ADD'
            }
          })
    },
    goMy() {
      this.$router.push({
            name: 'my-yy'
          })
    },
    searchXc() {
      this.getMyPassenger();
    },
    showTrip(e) {
      this.$router.push({
        name: "trip-info",
        query: {
          id: e.id,
        },
      });
    },
    sxTrip(e) {
      Dialog.confirm({
      title: '提醒',
      message: e.status == 1 ? '确定下架该商品？' : '确定上架商品吗？'
    })
      .then(() => {
        let params = {
          id: e.id
        }
        passenger.sxCai(params).then(res=>{
          if(res.data.code == 200) {
            Toast.success(res.data.message)
            this.getMyPassenger()
          }
        })
      })
      .catch(() => {
        // on cancel
      });
    },
    editTrip(e) {
      this.$router.push({
        name: "add-cp",
        query: {
          info: JSON.stringify(e),
          isAdd: 'edit'
        },
      });
    },
    showPassengerInfo() {
      this.$router.push({
        name: "passenger-info"
      });
    },
    onConfirm() {
      this.$refs.item.toggle();
    },
    time2(data) {
      if (data) {
        if (data == this.dayjs().format("YYYY-MM-DD")) {
          return "今天";
        } else if (
          this.dayjs(data) > this.dayjs() &&
          this.dayjs(data) < this.dayjs().add(1, "day")
        ) {
          return "明天";
        } else {
          return this.dayjs(data).format("MM月DD日");
        }
      }
    },
    getPassengerTripList() {
      this.isShowLoading = true;
      let params = {
        page: 1,
        pageSize: 15,
        searchkey: this.items[this.active].text,
      };
      passenger.getPassengerTripList(params).then((res) => {
        this.sfcList = res.data.data;
        this.isShowLoading = false;
      });
    },
    getPassengerTripDistrictList() {
      this.isShowLoading = true;
      let params = {};
      passenger.getPassengerTripDistrictList(params).then((res) => {
        for(let i in res.data.data) {
          for(let j in this.items) {
            if(res.data.data[i].endDistrict.substring(6,9) == this.items[j].text) {
              this.items[j].badge = res.data.data[i].count
            }
          }
        }
        this.isShowLoading = false;
      });
    },
  },
};
</script>
<style scoped>
/deep/ .van-tree-select__nav {
  flex: 0.55;
}
/deep/ .van-info
{
  background: rgb(12, 151, 147);
}
/deep/ .van-sidebar-item--select::before {
  background-color: #42c6ff;
}
.redpoint{
  background: red;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1px 8px;
    font-size: 12px;
    color: #fff;
    border-radius: 10px;
}
.btm-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-top: 1px solid rgb(12, 151, 147);
  background: #fff;
  /* box-shadow: 0 2px 10px -4px rgba(2,66,58,.8); */
}
.gwc-box {
  position: absolute;
  top: -10px;
  left: 20px;;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid rgb(12, 151, 147);
  background: #f2f2f2;
}
.sub {
  padding: 6px 15px;
  border-radius: 20px;
  margin-right: 15px;
  background: rgb(12, 151, 147);
  color: #fff;
}
.sub1 {
  padding: 6px 15px;
  border-radius: 20px;
  margin-right: 15px;
  background: #f2f2f2;
  color: #fff;
}
.item-box {
  display: flex;
  width: 90%;
  margin-left: 5%;
  height: 90px;
  border-bottom: 1px solid #f2f2f2;
  /* margin-bottom: 10px; */
  padding: 10px 0;
}
.cptitle {
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
}
.top-box {width: 90%;margin-left: 5%;display: flex;align-items: center;justify-content: space-between;padding-top: 21px;z-index: 2;}
.top-box div p {font-size: 16px;color: #FAFAFA;line-height: 0;}
.riqi {display: flex;align-items: center;}
.riqi img {margin-right: 5px;}
.riqi span {font-size: 13px;color: #FAFAFA;}
.head-sty {width: 44px;height: 44px;border-radius: 50%;}
.dcx {
  position: absolute;
  right: 0;
  bottom: 150px;
  z-index: 999;
  background: rgb(12, 151, 147);
  color: #fff;
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.wdxc {
  position: absolute;
  z-index: 9999;
  color: #fff;
}
.list-item {
  width: 92%;
  margin-left: 4%;
  background: #fff;
  box-shadow: 0px 2px 10px -4px rgba(2, 66, 58, 0.2);
  border-radius: 8px;
  padding: 15px 15px 10px 15px;
  margin-bottom: 15px;
}
.cklx {
  border: 1px solid #2d83e8;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #2d83e8;
}
.bar-box {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
.show-more {
  text-align: center;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #2d83e8;
  margin-bottom: 10px;
  cursor: pointer;
}
.bar-item {
  flex: 1;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background: #fff;
  justify-content: center;
}
.bar-item img {
  width: 20px;
  margin-right: 5px;
}
</style>
